import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface FilterProps {
  fieldName: string;
  dropDownItems: any;
  selectedFilterValue: string;
  handleChange: (filters: any) => void;
}

export const FilterDropDown: React.FC<FilterProps> = ({
  fieldName,
  handleChange,
  dropDownItems,
  selectedFilterValue,
}) => {
  const filterBoxStyles = { mb: 2, ml: 0.1, mr: 0.1 };

  // Ensure the selectedFilterValue is in the dropDownItems and sort the list
  const updatedDropDownItems = dropDownItems.includes(selectedFilterValue)
    ? dropDownItems
    : [...dropDownItems, selectedFilterValue];

  updatedDropDownItems.sort((a, b) => a.localeCompare(b));

  return (
    <FormControl
      variant="filled"
      color="secondary"
      sx={filterBoxStyles}
      size="small"
    >
      <InputLabel>{fieldName}</InputLabel>
      <Select
        name={fieldName.toLowerCase()}
        value={selectedFilterValue}
        onChange={(e) => handleChange(e)}
        sx={{ textTransform: 'capitalize' }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {updatedDropDownItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            value={menuItem}
            sx={{ textTransform: 'capitalize' }}
          >
            {menuItem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
