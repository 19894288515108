import { Box, Container, Grid, Typography } from '@mui/material';
import { EventsList } from '../components/EventsList';
import TopArtists from '../components/TopArtists';
import TopVenues from '../components/TopVenues';
import { Event as EventType } from '../types';
import { EventFilter } from '../components/EventFilter';
import { CTAButton, initGoogleAds, useEvents } from '../components/Utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SEOLocalityTitle } from '../Constants';

export type GroupedEventsType = {
  weekendEvents?: EventType[];
  upcomingEvents?: EventType[];
  featuredEvents?: EventType[];
  pastEvents?: EventType[];
};

type MainProps = { showTagHeader?: boolean };

export const Main: React.FC<MainProps> = ({ showTagHeader }) => {
  const { filters, setFilters, events } = useEvents();
  const { tag }: { tag?: string } = useParams();
  useEffect(() => {
    if (!events) {
      return;
    }
    initGoogleAds();
  }, [events]);
  return (
    <Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CTAButton
              href="/contact#contact-us"
              text="List Your Event!"
              fullWidth
              mb={2}
            />
            <EventFilter
              showTagHeader={showTagHeader}
              filters={filters}
              setFilters={setFilters}
            />
            <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
              <TopArtists />
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopVenues />
              <Box mt={2}>
                <ins
                  className="ADSENSE"
                  style={{ display: 'block' }}
                  data-ad-client="ca-pub-2902939122125433"
                  data-ad-slot="7395094731"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {showTagHeader && (
              <Typography variant="h1" textAlign="center">
                {SEOLocalityTitle}{' '}
                <Box component="em" sx={{ textTransform: 'capitalize' }}>
                  {tag}
                </Box>{' '}
                Events
              </Typography>
            )}
            <EventsList events={events} filters={filters || {}} />
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'inherit', md: 'none' } }}>
            <Box width="100%">
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopArtists />
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopVenues />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
