import { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
  ImageField,
  ReferenceField,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  Labeled,
  useCreateSuggestionContext,
  AutocompleteArrayInput,
  usePermissions,
  ArrayField,
  SelectInput,
  SaveButton,
  ImageInput,
  DateInput,
  Filter,
  useListContext,
  BooleanInput,
  DeleteButton,
  useNotify,
} from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { CreateVenueDialog } from './Venues';
import {
  Alert,
  Box,
  Chip,
  Dialog,
  Grid,
  TextField as MUITextField,
  Link,
} from '@mui/material';
import moment from 'moment';
import { dateFormat } from '../../App';
import TagsService from '../../services/Tags';
import ArtistsService from '../../services/Artists';
import { ArtistForm } from './Artists';
import CustomAuthProvider from '../../components/admin/CustomAuthProvider';
import { AiAnalyzeImageInput } from '../../components/OpenAI';

interface EventsFilterProps {
  [key: string]: any;
}
const repeatOptions = [
  { id: '', name: 'Once' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
  { id: 'First Friday', name: 'First Friday' },
  // Add other repeat options as needed
];

export const EventsFilter = (props: EventsFilterProps) => (
  <Filter {...props}>
    <ReferenceInput
      label="Venue Name"
      source="venue_id"
      reference="venues"
      alwaysOn
    >
      <AutocompleteInput
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
    <TextInput label="Event Title" source="title" alwaysOn />
    <ReferenceInput
      label="Artists"
      source="artists.id"
      reference="artists"
      alwaysOn
    >
      <AutocompleteInput
        label="Artist Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
    <DateInput
      label="Start Date & Time"
      source="datetime_start:gte"
      parse={(date) => moment(date).startOf('day').toISOString()}
    />
    <DateInput
      label="End Date & Time"
      source="datetime_start:lte"
      parse={(date) => moment(date).startOf('day').toISOString()}
    />
    <TextInput label="Created By (User Name)" source="created_by_name" />
    <SelectInput label="Repeat" source="repeat" choices={repeatOptions} />
    <ReferenceInput label="Tags" source="tags.event_id" reference="tags">
      <AutocompleteInput
        label="Tag Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);

export const EventIcon = CalendarIcon;
const EventDateTime = ({ control, defaultData, isEdit = false }: any) => {
  const now = moment().format(dateFormat);
  const [startDateTime, setStartDateTime] = useState<string>();
  const [endDateTime, setEndDateTime] = useState<string>();
  const [hasSetFromDefaultData, setHasSetFromDefaultData] =
    useState<boolean>(false);
  const formContext = useFormContext();
  const [inboundDefaultStart, inboundDefaultEnd] = formContext.getValues([
    'datetime_start',
    'datetime_end',
  ]);
  const defaultDatetimeStart = moment(
    inboundDefaultStart || startDateTime || defaultData?.datetime_start,
  ).format(dateFormat);
  const defaultDatetimeEnd = moment(
    inboundDefaultEnd || endDateTime || defaultData?.datetime_end,
  ).format(dateFormat);

  const { field: fieldStartDateTime } = useController({
    name: 'datetime_start',
    control,
    defaultValue: defaultDatetimeStart || now,
  });

  const startMin = isEdit ? defaultDatetimeStart || now : now;

  const { field: fieldEndDateTime } = useController({
    name: 'datetime_end',
    control,
    defaultValue: defaultDatetimeEnd || now,
  });

  useEffect(() => {
    if (!hasSetFromDefaultData && defaultData) {
      if (defaultData?.datetime_start) {
        setStartDateTime(defaultData.datetime_start);
      }
      if (defaultData?.datetime_end) {
        setEndDateTime(defaultData.datetime_end);
      }
      setHasSetFromDefaultData(true);
    } else {
      setStartDateTime(defaultDatetimeStart);
      setEndDateTime(defaultDatetimeEnd);
    }
  }, [
    defaultData,
    defaultDatetimeEnd,
    defaultDatetimeStart,
    hasSetFromDefaultData,
  ]);

  return (
    <Box>
      <Labeled label="Start date/time" fullWidth>
        <MUITextField
          {...fieldStartDateTime}
          value={moment(startDateTime).format(dateFormat)}
          inputProps={{ min: startMin, type: 'datetime-local' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            fieldStartDateTime.onChange(e);
            fieldEndDateTime.onChange(e);
            setStartDateTime(e.target.value);
          }}
          fullWidth
        />
      </Labeled>

      <Labeled label="End date/time" fullWidth>
        <MUITextField
          {...fieldEndDateTime}
          value={moment(endDateTime).format(dateFormat)}
          inputProps={{
            min: startDateTime || defaultDatetimeStart || now,
            type: 'datetime-local',
          }}
          onChange={(e) => {
            fieldEndDateTime.onChange(e);
            setEndDateTime(e.target.value);
          }}
          fullWidth
        />
      </Labeled>
    </Box>
  );
};

const getFiltersForRole = (user) => {
  if (!user) return {};
  let searchFilter = {};
  let orFilters: any[] = [];
  switch (user.user_type) {
    case 'Admin':
      // Admins can see all events, so no filter is applied
      return {};

    case 'VenueOwner':
      orFilters.push({ created_by_id: { $eq: user.id } });
      if (user.managed_venues.length > 0) {
        orFilters.push({
          venue_id: { $in: user.managed_venues.map((mv) => mv.venue_id) },
        });
      }
      break;
    case 'Promoter':
      // Promoters can see events they have created, events at venues they manage,
      // and events with artists they manage
      orFilters.push({ created_by_id: { $eq: user.id } });
      if (user.managed_venues.length > 0) {
        orFilters.push({
          venue_id: { $in: user.managed_venues.map((mv) => mv.venue_id) },
        });
      }
      if (user.managed_artists.length > 0) {
        orFilters.push({
          'artists.id': { $in: user.managed_artists.map((ma) => ma.artist_id) },
        });
      }
      break;
    case 'Artist':
      // Artists can see events they have created and events with artists they manage
      orFilters.push({ created_by_id: { $eq: user.id } });
      if (user.managed_artists.length > 0) {
        orFilters.push({
          'artists.id': { $in: user.managed_artists.map((ma) => ma.artist_id) },
        });
      }
      break;
    default:
      // Default case if the user role is not recognized
      orFilters.push({ created_by_id: { $eq: 0 } });
      break;
  }
  searchFilter = {
    $or: orFilters,
  };
  return {
    q: { s: JSON.stringify(searchFilter), join: 'artists' },
  };
};

export const EventList = () => {
  const { permissions } = usePermissions();
  const [currentUser, setCurrentUser] = useState<null | any>(null);
  const filters = getFiltersForRole(currentUser);

  useEffect(() => {
    CustomAuthProvider.getUserDetails()
      .then((user) => setCurrentUser(user))
      .catch((error) => {
        console.error('Error fetching user details:', error);
        window.location.href = '/manage/login';
      });
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filter={filters}
      filters={permissions === 'Admin' ? <EventsFilter /> : undefined}
      exporter={false}
    >
      <Datagrid bulkActionButtons={permissions === 'Admin' ? undefined : false}>
        <ReferenceField
          source="id"
          reference="events"
          link={(record, reference) => `/event/${record.id}`}
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="venue_id"
          reference="venues"
          link={(record, reference) => `/manage/${reference}/${record.id}`}
        >
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="artists">
          <ManyToManyArtistsArrayField currentUser={currentUser} />
        </ArrayField>
        <TextField source="title" />
        <DateField source="datetime_start" />
        <DateField source="datetime_end" />
        <TextField source="tickets_url" />
        <ImageField source="poster_image_url" />
        <TextField source="created_by_id" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const ManyToManyArtistsArrayField = (props: any) => {
  const { currentUser } = props;
  const { data: artists = [] } = useListContext();
  return (
    <>
      {artists.map((artist) => {
        if (
          currentUser.managed_artists
            .map((ma) => ma.artist_id)
            .includes(artist.id) ||
          currentUser.user_type === 'Admin'
        ) {
          return (
            <Link
              href={`/manage/artists/${artist.id}/edit`}
              key={artist.id}
              style={{ textDecoration: 'none' }}
            >
              <Chip label={artist.name} clickable />
            </Link>
          );
        } else {
          return (
            <Link
              href={`/artist/${artist.id}`}
              key={artist.id}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <Chip label={artist.name} clickable />
            </Link>
          );
        }
      })}
    </>
  );
};

const EventTitle = () => {
  const record = useRecordContext();
  return <span>Event {record ? `"${record.title}"` : ''}</span>;
};

const SaveAndAddAnotherButton = () => {
  const notify = useNotify();
  return (
    <SaveButton
      type="button"
      label="Create and add another"
      variant="text"
      alwaysEnable={true}
      mutationOptions={{
        onSuccess: () => {
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        },
      }}
    />
  );
};

const SaveAndEditButton = () => {
  const notify = useNotify();
  return (
    <SaveButton
      type="button"
      label="Create and edit"
      variant="text"
      alwaysEnable={true}
      mutationOptions={{
        onSuccess: (data) => {
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
          setTimeout(() => {
            window.location.href = `/manage/events/${data.id}/edit`;
          }, 100);
        },
      }}
    />
  );
};

const EventForm = ({ showId = false, isEdit = false, ...props }) => {
  const { permissions } = usePermissions();
  const [currentUser, setCurrentUser] = useState<null | any>(null);
  const [defaultData, setDefaultData] = useState<any>({});

  const venueFilters =
    permissions === 'VenueOwner' && currentUser
      ? {
          q: {
            s: JSON.stringify({
              id: { $in: currentUser.managed_venues.map((mv) => mv.venue_id) },
            }),
          },
        }
      : undefined;

  useEffect(() => {
    CustomAuthProvider.getUserDetails()
      .then((user) => setCurrentUser(user))
      .catch((error) => {
        console.error('Error fetching user details:', error);
        window.location.href = '/manage/login';
      });
  }, []);

  if (!currentUser) {
    return null;
  }
  const { extra_data, ...defaultValues } = defaultData;

  return (
    <SimpleForm {...props} toolbar={false} defaultValues={defaultValues}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {permissions === 'Admin' && !isEdit ? (
            <AiAnalyzeImageInput
              placeholder="Drop a poster image to upload, or click to select"
              source="poster_image_url"
              label="Upload Event Image"
              dataCallback={setDefaultData}
            >
              <PreviewImage />
            </AiAnalyzeImageInput>
          ) : (
            <ImageInput
              placeholder="Drop a poster image to upload, or click to select"
              source="poster_image_url"
              label="Upload Event Image"
              accept={{ 'image/*': [] }}
            >
              <PreviewImage />
            </ImageInput>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {permissions === 'VenueOwner' ? (
            <ReferenceInput
              source="venue_id"
              reference="venues"
              filter={venueFilters}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          ) : (
            <ReferenceInput source="venue_id" reference="venues">
              <AutocompleteInput
                optionText="name"
                create={<CreateVenueDialog />}
                createItemLabel="Create new venue"
                filterToQuery={(q) => ({ name: `%${q}%` })}
                label="Event Venue (search by name)"
              />
            </ReferenceInput>
          )}

          {defaultData?.extra_data?.venue && (
            <Alert severity="error">
              The venue wasn't found, check the name and if it needs to be
              created: <br />
              {defaultData?.extra_data?.venue}
            </Alert>
          )}

          {currentUser.user_type === 'Admin' && (
            <BooleanInput source="featured" />
          )}
          <TextInput source="title" fullWidth={true} />
          <EventDateTime
            isEdit={isEdit}
            defaultData={defaultData}
            key="datetimewithdefaults"
          />
          <SelectInput
            fullWidth
            source="repeat"
            label="How often does this event happen?"
            emptyText={'Once'}
            choices={[
              { id: 'Weekly', name: 'Weekly' },
              { id: 'Monthly', name: 'Monthly' },
              { id: 'First Friday', name: 'First Friday' },
            ]}
          />
          <TextInput
            source="description"
            fullWidth={true}
            multiline
            minRows={6}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {showId && <TextInput disabled source="id" />}
          {/* Tag selection */}
          {defaultData?.extra_data?.tags?.length > 0 && (
            <Alert severity="error">
              These tags weren't found and need to be created:
              <pre>{defaultData?.extra_data?.tags.join(', ')}</pre>
            </Alert>
          )}
          <ReferenceArrayInput
            source="tags"
            reference="tags"
            label="Tags"
            parse={(value) => value && value.map((v: any) => ({ id: v }))}
            format={(value) => value && value.map((v: any) => v.id)}
          >
            <AutocompleteArrayInput
              create={<CreateTagDialog />}
              optionText="name"
              filterToQuery={(q) => ({ name: `%${q}%` })}
            />
          </ReferenceArrayInput>
          {defaultData?.extra_data?.artists?.length > 0 && (
            <Alert severity="error">
              These artists weren't found, check their names and if they need to
              be created:
              <pre>{defaultData?.extra_data?.artists.join('\n')}</pre>
            </Alert>
          )}
          <ReferenceArrayInput
            source="artists"
            reference="artists"
            label="Artists"
            defaultValues={defaultData.artists}
            parse={(value) => value && value.map((v: any) => ({ id: v }))}
            format={(value) => value && value.map((v: any) => v.id)}
          >
            <AutocompleteArrayInput
              create={<CreateArtistDialog />}
              optionText="name"
              filterToQuery={(q) => ({ name: `%${q}%` })}
              defaultValue={defaultData.artists}
            />
          </ReferenceArrayInput>
          <TextInput
            fullWidth={true}
            source="tickets_url"
            label="Buy Tickets URL"
            type="url"
          />
          <TextInput fullWidth={true} source="website" type="url" />
          <TextInput fullWidth={true} source="cost" />
        </Grid>
      </Grid>
      <Box display="flex" width="100%">
        <Box flexGrow="1">
          <SaveButton
            alwaysEnable={!isEdit}
            label={`${isEdit ? 'Update' : 'Create'} Event`}
          />
          {!isEdit && (
            <>
              <SaveAndAddAnotherButton />
              {permissions === 'Admin' && <SaveAndEditButton />}
            </>
          )}
        </Box>
        {isEdit && (
          <Box>
            <DeleteButton />
          </Box>
        )}
      </Box>
    </SimpleForm>
  );
};

export const EventEdit = () => {
  return (
    <Edit title={<EventTitle />} redirect="list" mutationMode="pessimistic">
      <EventForm showId={true} isEdit={true} />
    </Edit>
  );
};

export const EventCreate = () => {
  return (
    <Create title="Create an Event" redirect="list">
      <EventForm />
    </Create>
  );
};

export const PreviewImage = (props: any) => {
  const eventRecord = useRecordContext(props);
  if (!eventRecord) {
    return null;
  }

  return (
    <img
      src={`${eventRecord.src ? eventRecord.src : eventRecord}`}
      alt="poster"
      style={{ width: '100%' }}
    />
  );
};

export const CreateTagDialog = () => {
  const { onCancel, onCreate, filter } = useCreateSuggestionContext();

  const handleSubmit = async (data: any) => {
    const result = await TagsService.handleCreate({ data });
    onCreate(result.data);
  };

  return (
    <Dialog open onClose={onCancel}>
      <Create>
        <SimpleForm toolbar={false} onSubmit={handleSubmit}>
          <TextInput source="name" defaultValue={filter} />
          <SaveButton alwaysEnable={true} label={'Create Tag'} />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

export const CreateArtistDialog = () => {
  const { onCancel, onCreate, filter } = useCreateSuggestionContext();

  const handleSubmit = async (data: any) => {
    const result = await ArtistsService.handleCreate({ data });
    onCreate(result.data);
  };
  return (
    <Dialog open onClose={onCancel}>
      <Create>
        <ArtistForm onSubmit={handleSubmit} defaultName={filter} />
      </Create>
    </Dialog>
  );
};
