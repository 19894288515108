import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  TextInput,
  EditButton,
  SimpleForm,
  usePermissions,
  AutocompleteInput,
  Filter,
  ReferenceInput,
} from 'react-admin';


interface TagFilterProps {
  [key: string]: any;
}
export const TagFilter = (props: TagFilterProps) => (
  <Filter {...props}>
    <ReferenceInput
      label="Tag"
      source="id"
      reference="tags"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="Tag"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);
export const TagList = (props: any) => {
  const { permissions } = usePermissions();
  return (
    <List {...props} filters={<TagFilter />}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={permissions === 'Admin' ? undefined : false}
      >
        <TextField source="id" />
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const TagEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const TagCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
