import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Create,
  useRecordContext,
  usePermissions,
  SaveButton,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  UrlField,
} from 'react-admin';
import PianoIcon from '@mui/icons-material/Piano';
import CustomAuthProvider from '../../components/admin/CustomAuthProvider';
export const ArtistIcon = PianoIcon;

export const ArtistsFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Artists" source="id" reference="artists" alwaysOn>
      <AutocompleteInput
        label="Artist Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);

const getFiltersForRole = (user) => {
  if (!user) return {};
  let searchFilter = {};
  let orFilters: any[] = [];
  switch (user.user_type) {
    case 'Admin':
      // Admins can see all artists, so no filter is applied
      return {};

    case 'VenueOwner':
    case 'Promoter':
    case 'Artist':
    default:
      const managedArtists = user.managed_artists || [];
      managedArtists.push({ artist_id: 0 });
      orFilters.push({
        id: { $in: managedArtists.map((ma) => ma.artist_id) },
      });
      break;
  }

  searchFilter = {
    $or: orFilters,
  };
  return {
    q: { s: JSON.stringify(searchFilter) },
  };
};

export const ArtistList = (props: any) => {
  const { permissions } = usePermissions();

  const [currentUser, setCurrentUser] = React.useState<null | any>(null);
  const filters = getFiltersForRole(currentUser);

  React.useEffect(() => {
    CustomAuthProvider.getUserDetails()
      .then((user) => setCurrentUser(user))
      .catch((error) => console.error('Error fetching user details:', error));
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <List filter={filters} filters={<ArtistsFilter />} {...props}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={permissions === 'Admin' ? undefined : false}
      >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <ImageField source="image_url" />
        <UrlField source="website" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ArtistEdit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <ArtistForm />
  </Edit>
);

export const ArtistCreate = (props: any) => (
  <Create {...props}>
    <ArtistForm />
  </Create>
);

export const PreviewImage = (props: any) => {
  const artistRecord = useRecordContext(props);
  if (!artistRecord) {
    return null;
  }

  return (
    <img
      width="200"
      src={`${artistRecord.src ? artistRecord.src : artistRecord}`}
      alt="poster"
    />
  );
};

export const ArtistForm = (props: any) => {
  const { defaultName = '', ...rest } = props;
  return (
    <SimpleForm toolbar={false} {...rest}>
      <h1>Artist Info</h1>
      <TextInput disabled source="id" />
      <ImageInput source="image_url" accept={{"image/*": []}} fullWidth>
        <PreviewImage />
      </ImageInput>
      <TextInput source="name" defaultValue={defaultName} fullWidth />
      <TextInput multiline source="description" fullWidth minRows={2} />
      <TextInput source="website" type="url" fullWidth />
      <TextInput source="facebook_username" fullWidth />
      <TextInput source="instagram_username" fullWidth />
      <TextInput source="twitter_username" fullWidth />
      <TextInput source="twitch_username" fullWidth />
      <TextInput source="soundcloud_username" fullWidth />
      <TextInput source="mixcloud_username" fullWidth />
      <TextInput source="tiktok_username" fullWidth />
      <TextInput source="spotify_username" fullWidth />
      <TextInput source="youtube_username" fullWidth />
      <TextInput source="vimeo_username" fullWidth />
      <SaveButton alwaysEnable={true} />
    </SimpleForm>
  );
};
