import { Event as EventType } from '../types'; // Adjust import path as necessary
const groupEventsByUpcoming = (events) => {
  const weekendEvents: EventType[] = [];
  const upcomingEvents: EventType[] = [];
  const featuredEvents: EventType[] = [];
  const pastEvents: EventType[] = [];

  const now = new Date();
  const currentDay = now.getDay(); // Getting the local current day of the week

  // Initialize startDay and endDay with local time (considering the whole local day)
  const startDay: Date = new Date(now);
  startDay.setHours(0, 0, 0, 0);
  const endDay: Date = new Date(now);
  endDay.setHours(23, 59, 59, 999);

  // The rolling window ends on the next Sunday at 23:59:59.999 local time
  endDay.setDate(now.getDate() + (7 - currentDay));

  // Convert the local startDay and endDay to UTC for comparison
  const startDayUTC = new Date(
    startDay.getTime() + startDay.getTimezoneOffset() * 60000,
  );
  const endDayUTC = new Date(
    endDay.getTime() + endDay.getTimezoneOffset() * 60000,
  );

  for (const event of events) {
    const eventDate = new Date(event.datetime_start);
    if (eventDate >= startDayUTC && eventDate <= endDayUTC) {
      weekendEvents.push(event);
    } else if (eventDate < now) {
      pastEvents.push(event);
    } else {
      upcomingEvents.push(event);
    }

    if (event.featured === true) {
      featuredEvents.push(event);
    }
  }
  return {
    weekendEvents,
    upcomingEvents,
    featuredEvents,
    pastEvents,
  };
};
export default groupEventsByUpcoming;
