import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Box,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Artist } from '../types';
import { TwitchIcon, SoundCloudIcon, MixCloudIcon, SpotifyIcon, TikTokIcon, VimeoIcon } from './SocialIcons';
import { YouTube } from '@mui/icons-material';

interface ArtistsListProps {
  artists: Artist[] | null;
}

const ArtistsList: React.FC<ArtistsListProps> = ({ artists }) => {
  if (!artists || artists.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1} mt={0}>
      <Grid item xs={12}>
        <Typography variant="h6" mb={0}>
          Performing Artists:
        </Typography>
      </Grid>
      {artists.map((artist) => (
        <Grid item xs={12} key={artist.id}>
          <Card>
            <Grid container alignItems={'center'}>
              {artist.image_url && (
                <Grid item xs={12} sm={4}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={artist.image_url}
                    alt={artist.name}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={8}>
                <CardContent>
                  <Typography variant="h5">
                    <RouterLink to={`/artist/${artist.id}`}>
                      {artist.name}
                    </RouterLink>
                  </Typography>
                  {artist.description && (
                    <Typography variant="body1" mt={1}>
                      {artist.description}
                    </Typography>
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                    {artist.soundcloud_username && (
                      <Link
                        target="_blank"
                        href={`https://www.soundcloud.com/${artist.soundcloud_username}`}
                        color="ctaColor.main"
                      >
                        <SoundCloudIcon />
                      </Link>
                    )}
                    {artist.twitch_username && (
                      <Link
                        target="_blank"
                        href={`https://www.twitch.com/${artist.twitch_username}`}
                        color="ctaColor.main"
                      >
                        <TwitchIcon />
                      </Link>
                    )}
                    {artist.mixcloud_username && (
                      <Link
                        target="_blank"
                        href={`https://www.mixcloud.com/${artist.mixcloud_username}`}
                        color="ctaColor.main"
                      >
                        <MixCloudIcon />
                      </Link>
                    )}
                    {artist.spotify_username && (
                      <Link
                        target="_blank"
                        href={`https://open.spotify.com/user/${artist.spotify_username}`}
                        color="ctaColor.main"
                      >
                        <SpotifyIcon />
                      </Link>
                    )}
                    {artist.youtube_username && (
                      <Link
                        target="_blank"
                        href={`https://www.youtube.com/@${artist.youtube_username}`}
                        color="ctaColor.main"
                      >
                        <YouTube />
                      </Link>
                    )}
                    {artist.vimeo_username && (
                      <Link
                        target="_blank"
                        href={`https://vimeo.com/${artist.vimeo_username}`}
                        color="ctaColor.main"
                      >
                        <VimeoIcon />
                      </Link>
                    )}
                    {artist.tiktok_username && (
                      <Link
                        target="_blank"
                        href={`https://www.tiktok.com/@${artist.tiktok_username}`}
                        color="ctaColor.main"
                      >
                        <TikTokIcon />
                      </Link>
                    )}
                    {artist.facebook_username && (
                      <Link
                        target="_blank"
                        href={`https://www.facebook.com/${artist.facebook_username}`}
                        color="ctaColor.main"
                      >
                        <FacebookIcon />
                      </Link>
                    )}
                    {artist.twitter_username && (
                      <Link
                        target="_blank"
                        href={`https://www.twitter.com/${artist.twitter_username}`}
                        color="ctaColor.main"
                      >
                        <TwitterIcon />
                      </Link>
                    )}
                    {artist.instagram_username && (
                      <Link
                        target="_blank"
                        href={`https://www.instagram.com/${artist.instagram_username}`}
                        color="ctaColor.main"
                      >
                        <InstagramIcon />
                      </Link>
                    )}
                  </Box>
                  {artist.website && (
                    <Typography mb={1}>
                      <Link target="_blank" color="ctaColor.main" href={artist.website}>
                        {artist.website}
                      </Link>
                    </Typography>
                  )}
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ArtistsList;
