import { useEffect, useState } from 'react';
import VenuesService from '../services/Venues';
import { Typography, Box} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CTAButton } from './Utils';

const TopVenues = () => {
  const [topVenues, setTopVenues] = useState<any[]>([]);

  useEffect(() => {
    const fetchTopVenues = async () => {
      const Venues = await VenuesService.getTopVenues();
      setTopVenues(Venues);
    };

    fetchTopVenues();
  }, []);

  if (!topVenues || topVenues.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography variant="h4" mb={1}>
        Top Venues
      </Typography>
      <Box maxHeight="300px" sx={{overflowY: 'scroll'}} mb={1}>
        {topVenues.map((venue) => (
          <div key={venue.id}>
            <Typography variant="h5">
              <RouterLink to={`/venue/${venue.id}`}>{venue.name}</RouterLink>
            </Typography>
          </div>
        ))}
      </Box>
      <CTAButton href="/venues" text="View All Venues" fullWidth />
    </div>
  );
};

export default TopVenues;
