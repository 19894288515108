import { Box } from '@mui/material';
import { Artist, Event as EventType } from '../types';
import { EventIcon } from '../views/admin/Events';

export const EventImage = ({
  event,
  ...props
}: {
  event: EventType;
  [key: string]: any;
}) => {
  let imageSrc;
  if (event.poster_image_url) {
    imageSrc = event.poster_image_url;
  } else if (
    event.artists.filter((artist: Artist) => artist.image_url).length > 0
  ) {
    imageSrc = event.artists.filter((artist: Artist) => artist.image_url)[0]
      .image_url;
  } else if (
    event.venue['logo_image_url']
  ) {
    imageSrc = event.venue['logo_image_url'];
  }
  return imageSrc ? (
    <Box
      component="img"
      src={imageSrc}
      alt="poster"
      sx={{ width: '100%' }}
      {...props}
    />
  ) : (
    <EventIcon />
  );
};
