import moment from 'moment';
import { CreateParams, UpdateParams } from 'react-admin';
import {
  ADMIN_API_ROOT,
  API_ROOT,
  dataProvider,
} from '../components/admin/CustomDataProvider';
import { Event } from '../types';
export function getNextFirstFriday(currentDate: moment.Moment): moment.Moment {
  let nextMonth = currentDate.clone().add(1, 'month').startOf('month');
  let daysUntilFirstFriday = (5 - nextMonth.day()) % 7;
  if (daysUntilFirstFriday === 0) daysUntilFirstFriday = 7; // If the first day is Friday, set to the next week
  let firstFriday = nextMonth.add(daysUntilFirstFriday, 'days');
  return firstFriday;
}
export function setRepeatingEventNextDate(event: Event): Event {
  let eventStart = moment(event.datetime_start);
  let now = moment();
  if (moment(event.datetime_end).isBefore(now)) {
    let weeksToAdd, monthsToAdd;
    switch (event.repeat) {
      case 'Weekly':
        weeksToAdd = Math.ceil(now.diff(eventStart, 'weeks', true));
        eventStart.add(weeksToAdd, 'weeks');
        break;
      case 'Monthly':
        monthsToAdd = Math.ceil(now.diff(eventStart, 'months', true));
        eventStart.add(monthsToAdd, 'months');
        break;
      case 'First Friday':
        eventStart = getNextFirstFriday(now);
        break;
    }

    // Calculate the new end date based on the updated start date and original event duration
    let eventEnd = moment(event.datetime_end);
    const duration = eventEnd.diff(moment(event.datetime_start));
    event.datetime_start = eventStart.toISOString();
    event.datetime_end = eventStart
      .clone()
      .add(duration, 'milliseconds')
      .toISOString();
  }
  return event;
}

export default class EventsService {
  public static async getEvents(query: string = '') {
    const searchFilter = JSON.stringify({
      $or: [
        {
          datetime_start: { $gte: moment().startOf('day').toISOString() },
        },
        {
          repeat: { $in: ['Weekly', 'Monthly', 'First Friday'] },
        },
      ],
    });

    const response = await fetch(
      `${API_ROOT}/events?sort[0]=datetime_start,ASC&s=${searchFilter}`,
      {
        method: 'GET',
      },
    );

    if (!response.ok) {
      throw new Error('Failed to get events');
    }
    let events = await response.json();
    return events
      .map(setRepeatingEventNextDate)
      .sort((a, b) => moment(a.datetime_start).diff(moment(b.datetime_start)));
  }

  public static async getEvent(id: number) {
    const response = await fetch(`${API_ROOT}/events/${id}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Failed to get events');
    }
    return await response.json();
  }

  public static async updateEventPoster(eventId: string, file: File) {
    if (!file) {
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${ADMIN_API_ROOT}/events/${eventId}/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to update event poster');
    }
    return await response.json();
  }

  public static async handleCreate(params: CreateParams<any>) {
    const { poster_image_url, ...newData } = params.data;
    const newParams = {
      meta: params.meta,
      data: newData,
    };
    newParams.data.datetime_start = moment(
      newParams.data.datetime_start,
    ).toISOString();
    newParams.data.datetime_end = moment(
      newParams.data.datetime_end,
    ).toISOString();

    // use the url we already have if we got it back from AI upload
    if (poster_image_url && !poster_image_url?.rawFile) {
      newParams.data.poster_image_url = poster_image_url;
    }
    const result = await dataProvider.create('Events', newParams);

    // use the newly uploaded file otherwise
    if (result?.data?.id && poster_image_url?.rawFile) {
      const posterResult = await this.updateEventPoster(
        result.data.id,
        poster_image_url.rawFile,
      );

      if (posterResult) {
        result.data = posterResult.updatedEvent;
      }
    }
    return result;
  }

  public static async handleUpdate(params: UpdateParams<any>) {
    const { poster_image_url, ...newData } = params.data;
    const newParams = {
      id: params.id,
      previousData: params.previousData,
      meta: params.meta,
      data: newData,
    };
    newParams.data.datetime_start = moment(
      newParams.data.datetime_start,
    ).toISOString();
    newParams.data.datetime_end = moment(
      newParams.data.datetime_end,
    ).toISOString();
    if (!poster_image_url) {
      newParams.data.poster_image_url = '';
    }
    const result = await dataProvider.update('Events', newParams);
    if (poster_image_url?.rawFile) {
      const posterResult = await this.updateEventPoster(
        params.id,
        poster_image_url.rawFile,
      );
      if (posterResult) {
        result.data = posterResult.updatedEvent;
      }
    }
    return result;
  }
}
