import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  EditButton,
  ReferenceInput,
  usePermissions,
  AutocompleteInput,
  ReferenceField,
  Filter,
} from 'react-admin';
import Icon from '@mui/icons-material/BusinessCenter';
export const VenueUsersIcon = Icon;
interface VenueManagerFilterProps {
  [key: string]: any;
}

export const VenueManagerFilter = (props: VenueManagerFilterProps) => (
  <Filter {...props}>
    <ReferenceInput
      label="Venue Name"
      source="venue_id" 
      reference="venues"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="Venue Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput
      label="User Name"
      source="user_id" 
      reference="users"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="User Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);

export const VenueUsersList = (props: any) => {
  const { permissions } = usePermissions();
  return (
    <List {...props} sort={{ field: 'user_id', order: 'ASC' }} filters={<VenueManagerFilter />}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={permissions === 'Admin' ? undefined : false}
      >
        <ReferenceField source="user_id" reference="users">
          <TextField source="name" />
          ,&nbsp;
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="venue_id" reference="venues">
          <TextField source="name" />
        </ReferenceField>
        {permissions === 'Admin' && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const VenueUsersEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="user_id" />
        <TextInput disabled source="venue_id" />
      </SimpleForm>
    </Edit>
  );
};

export const VenueUsersCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="user_id" reference="users">
          <AutocompleteInput
            optionText={(u) => `${u.name}, ${u.email}`}
            filterToQuery={(q) => ({ name: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="venue_id" reference="venues">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(q) => ({ name: q })}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
