import {
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContactForm } from './ContactForm';
import ContactBackground from './images/ContactBackground.png';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Contact = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        element.focus();
      }
    }
  }, [hash]);  return (
    <Paper sx={{ p: 4, my: 4, position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            Get Involved
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Are you a promoter, band, or local venue interested in listing your
            events with us? The first step is getting set up with an account on
            our platform. Send us a message letting us know who you are and
            we'll get you setup with an account so you can start reaching a wide
            audience of potential attendees.
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} textAlign="center">
          <img
            src={ContactBackground}
            alt="Community gathering"
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography
            variant="h5"
            color="textPrimary"
            textAlign={'right'}
            gutterBottom
          >
            Enhance Your Experience
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            paragraph
            textAlign="right"
          >
            We're dedicated to enhancing the experience on RogueValley.Events
            for all our users. If you have suggestions for improvements or
            features you'd like to see, don't hesitate to reach out. Your
            support and participation are what make our community vibrant. We're
            excited to see what events you'll bring to the Rogue Valley!
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FAQ />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactForm />
        </Grid>
      </Grid>
    </Paper>
  );
};

const FAQ = () => {
  return (
    <>
      <Typography variant="h5" color="textSecondary" sx={{ mt: 1, mb: 1 }}>
        Frequently Asked Questions
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How do I list my events?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Start by sending us a message! We'll guide you through setting up an
            account so you can post your events and attract attendees. Feel free
            to send us your event posters and we'll get it posted ASAP.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I embed my events on my website?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can! We're still working on the UI to make it generally
            available, but contact us to let us know you're interested and we
            can get you setup. Check out our very own resident DJ's website -{' '}
            <a href="https://djberadley.com">DJBeRadley.com</a>, proudly powered
            by RogueValley.Events!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I list recurring events?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can! Right now our functionality is limited to either
            weekly, monthly, or first friday. We have plans to add more options,
            so let us know if there a "recurring" option you'd like to see.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I update my venue or artist listing page?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, when you reach out to have us setup your account, let us know
            which artist(s) and venue(s) you're managing. We do manual
            verification prior to granting access.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I suggest features or improvements?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely! We love hearing from our community. Your suggestions
            help us make RogueValley.Events even better.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I feature my event?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can! Featuring your event helps it gain more visibility
            among our users. Reach out to us for more details on how to get your
            event featured.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
