import { Box, Container, Link } from '@mui/material';
import { Copyright } from './App';
import { Header } from './Header';
import avmLogoSrc from './images/ashland-visitors-map-1024.png';
export const initialHeight = {
  xs: 'calc(100vh - 88px)',
  sm: 'calc(100vh - 92px)',
};

export const MainContainer = (props: { content: JSX.Element }) => {
  const { content } = props;
  return (
    <>
      <Header />
      <Container component="main">
        <Box
          sx={{
            mt: '125px',
            mb: 4,
            minHeight: initialHeight,
          }}
        >
          {content}
          <Box textAlign="center" mb={4}>
            <h4>
              Check out our partner,{' '}
              <Link
                target="_blank"
                color="secondary"
                href="https://ashlandvisitorsmap.com"
              >
                AshlandVisitorsMap.com
              </Link>{' '}
              for top attractions, dining, and more!
            </h4>
            <Link href="https://ashlandvisitorsmap.com" target="_blank">
              <Box component="img" src={avmLogoSrc} sx={{ maxWidth: '100%' }} />
            </Link>
          </Box>
          <Box
            sx={{ textAlign: 'center' }}
            className="sender-form-field"
            data-sender-form-id="m3401b8rbdk9v6hydue"
          ></Box>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};
