// src/components/EmbedEvents.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { Event as EventType } from '../types';
import VenuesService from '../services/Venues';
import ArtistsService from '../services/Artists';
import { Grid, Typography } from '@mui/material';
import { EventListItem } from '../components/EventsList';
import groupEventsByUpcoming from '../utilities/Events';

const EmbedEvents: React.FC = () => {
  const { type, id } = useParams<{
    type: 'artist' | 'venue';
    id: string;
    showPastEvents?: 'true' | 'false';
    showTitle?: 'true' | 'false';
  }>();
  const location = useLocation();
  // Extracting URLSearchParams from the current location's search
  const searchParams = new URLSearchParams(location.search);
  const showPastEvents = searchParams.get('showPastEvents') !== 'false';
  const showUpcomingEvents = searchParams.get('showUpcomingEvents') !== 'false';
  const showWeekendEvents = searchParams.get('showWeekendEvents') !== 'false';
  const backgroundColor = searchParams.get('bgColor') || 'FFFFFF';
  const color = searchParams.get('fontColor') || '000000';
  const showTitle = searchParams.get('showTitle') !== 'false';
  const target = searchParams.get('target');
  const [embedObject, setEmbedObject] = useState<any>();
  const [eventsFetched, setEventsFetched] = useState<boolean>(false);
  const [groupedEvents, setGroupedEvents] = useState<{
    weekendEvents?: EventType[];
    upcomingEvents?: EventType[];
    featuredEvents?: EventType[];
    pastEvents?: EventType[];
  }>({});
  const postHeightToParent = useCallback(() => {
    const height = document.documentElement.scrollHeight;
    window.parent.postMessage({ height, target }, '*'); // Adjust the target origin as necessary
  }, [target]);

  const fetchEvents = useCallback(async () => {
    const embedObjectData =
      type === 'venue'
        ? await VenuesService.getVenue(Number(id))
        : await ArtistsService.getArtist(Number(id));
    setGroupedEvents(groupEventsByUpcoming(embedObjectData.data.events));
    setEventsFetched(true);
    setEmbedObject(embedObjectData.data);
  }, [id, type]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (eventsFetched) {
      // do initial resize
      postHeightToParent();

      // Select all images within the component
      const images = document.querySelectorAll('img');
      let loadedImages = 0;

      const onImageLoaded = () => {
        loadedImages++;
        // If all images have loaded, call postHeightToParent
        if (loadedImages === images.length) {
          postHeightToParent();
        }
      };

      images.forEach((img) => {
        // If the image is already loaded (e.g., from cache), immediately increment the count
        if (img.complete) {
          onImageLoaded();
        } else {
          // Otherwise, add an event listener for when the image does load
          img.addEventListener('load', onImageLoaded);
          // Optional: Add an error listener in case the image fails to load
          img.addEventListener('error', onImageLoaded);
        }
      });

      // If there are no images, call postHeightToParent immediately
      if (images.length === 0) {
        postHeightToParent();
      }
    }
  }, [postHeightToParent, eventsFetched]);

  useEffect(() => {
    const handleResize = () => {
      postHeightToParent();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [postHeightToParent]);

  if (!eventsFetched) {
    return null;
  } else if (!embedObject?.events?.length) {
    return 'No events to display... check back soon!';
  }

  return (
    <Box
      bgcolor={`#${backgroundColor}`}
      color={`#${color}`}
      minHeight="100vh"
      p={2}
      m={0}
    >
      {showTitle && (
        <Typography variant="h1" textAlign={'center'}>
          {type === 'venue'
            ? `Events at ${embedObject.name}`
            : `${embedObject.name} Events`}
        </Typography>
      )}
      <div>
        {showWeekendEvents &&
          groupedEvents.weekendEvents &&
          groupedEvents.weekendEvents.length > 0 && (
            <EventSection
              events={groupedEvents.weekendEvents.sort(
                (a: EventType, b: EventType) =>
                  new Date(a.datetime_start).getTime() -
                  new Date(b.datetime_start).getTime(),
              )}
              title="Events This Week"
            />
          )}
        {showUpcomingEvents &&
          groupedEvents.upcomingEvents &&
          groupedEvents.upcomingEvents.length > 0 && (
            <EventSection
              events={groupedEvents.upcomingEvents.sort(
                (a: EventType, b: EventType) =>
                  new Date(a.datetime_start).getTime() -
                  new Date(b.datetime_start).getTime(),
              )}
              title="Upcoming Events"
            />
          )}
        {showPastEvents &&
          groupedEvents.pastEvents &&
          groupedEvents.pastEvents.length > 0 && (
            <EventSection
              events={groupedEvents.pastEvents.sort(
                (a: EventType, b: EventType) =>
                  new Date(b.datetime_start).getTime() -
                  new Date(a.datetime_start).getTime(),
              )}
              title="Past Events"
            />
          )}
      </div>
      <Typography textAlign="center" p={2}>
        Powered by{' '}
        <Link
          color={`#${color}`}
          href="https://roguevalley.events"
          target="_blank"
        >
          RogueValley.Events
        </Link>!
      </Typography>
    </Box>
  );
};

interface EventSectionProps {
  title: string;
  events: EventType[];
}

const EventSection: React.FC<EventSectionProps> = ({ title, events }) => {
  const handleOpenEventDetails = (eventId) => {
    window.open(`/event/${eventId}`);
  };
  return (
    <Box mb={2}>
      <Typography variant="h4" textAlign="center">
        {title}
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} md={3} key={event.id}>
            <EventListItem
              event={event}
              handleOpenEventDetails={() => handleOpenEventDetails(event.id)}
              embed
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EmbedEvents;
