import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import VenueCard from '../VenueCard';
import { regularDataProvider } from '../admin/CustomDataProvider';
import { Venue } from '../../types';

export const VenueListingPage: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadVenues = async () => {
      try {
        const response = await regularDataProvider.getList('venues', {
          pagination: { page: 1, perPage: 10000 }, // Adjust pagination as needed
          sort: { field: 'name', order: 'ASC' }, // Example sorting, adjust as needed
        });
        setVenues(response.data);
      } catch (error) {
        console.error('Failed to load venues:', error);
      } finally {
        setLoading(false);
      }
    };

    loadVenues();
  }, []);

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Venues
      </Typography>
      {loading ? (
        <Typography variant="body1">Loading...</Typography>
      ) : (
        <Grid container spacing={4}>
          {venues.map((venue) => (
            <Grid item xs={12} sm={6} md={4} key={venue.id}>
              <VenueCard venue={venue} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default VenueListingPage;
