import { CreateParams, UpdateParams } from 'react-admin';
import {
  ADMIN_API_ROOT,
  API_ROOT,
  dataProvider,
  regularDataProvider
} from '../components/admin/CustomDataProvider';

export default class VenuesService {
  public static async updateVenueLogoImage(venueId: string, file: File) {
    if (!file) {
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${ADMIN_API_ROOT}/venues/${venueId}/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `bearer ${localStorage.getItem('access_token')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to update venue logo');
    }
    return await response.json();
  }

  public static async getVenue(id: number) {
    return await regularDataProvider.getOne('Venues', { id });
  }

  public static async handleCreate(params: CreateParams<any>) {
    const { logo_image_url, ...newData } = params.data;
    const newParams = {
      meta: params.meta,
      data: newData,
    };
    const result = await dataProvider.create('Venues', newParams);
    if (result?.data?.id && logo_image_url?.rawFile) {
      const logoResult = await this.updateVenueLogoImage(
        result.data.id,
        logo_image_url.rawFile,
      );

      if (logoResult) {
        result.data = logoResult.updatedVenue;
      }
    }
    return result;
  }

  public static async handleUpdate(params: UpdateParams<any>) {
    const { logo_image_url, ...newData } = params.data;
    const newParams = {
      id: params.id,
      previousData: params.previousData,
      meta: params.meta,
      data: newData,
    };
    if (!logo_image_url) {
      newParams.data.logo_image_url = '';
    }
    const result = await dataProvider.update('Venues', newParams);
    if (logo_image_url?.rawFile) {
      const logoResult = await this.updateVenueLogoImage(
        params.id,
        logo_image_url.rawFile,
      );
      if (logoResult) {
        result.data = logoResult.updatedVenue;
      }
    }
    return result;
  }

  public static async getTopVenues() {
    const response = await fetch(`${API_ROOT}/venues/top`);
    if (!response.ok) {
      throw new Error('Failed to fetch top venues');
    }
    return await response.json();
  }
}
