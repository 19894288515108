import { Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { MenuElement } from './HeaderMenu';
import { FacebookRounded, Instagram } from '@mui/icons-material';
export default function DesktopMenu({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  return (
    <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
      {menuElements.map((el) => (
        <Link
          key={el.name}
          component={
            el.name === 'Contact' || el.name === 'Home' ? 'a' : RouterLink
          }
          underline="hover"
          to={el.to}
          href={el.to}
          p={2}
          sx={{
            color: (theme) => theme.palette.ctaColor?.main,
            fontFamily: '"kon-tiki-aloha-jf", sans-serif',
          }}
        >
          {el.name}
        </Link>
      ))}
      <Link
        p={1}
        mt={'8px'}
        sx={{ color: (theme) => theme.palette.ctaColor?.main }}
        href="https://instagram.com/roguevalley.events"
      >
        <Instagram />
      </Link>
      <Link
        p={1}
        mt={'8px'}
        sx={{ color: (theme) => theme.palette.ctaColor?.main }}
        href="https://www.facebook.com/TheRogueValleyEvents"
      >
        <FacebookRounded />
      </Link>
    </Box>
  );
}
