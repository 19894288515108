import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { LoadingPage } from 'react-admin';
import { Artist, Event as EventType, Tag } from '../types';
import EventCard from './EventCard';
import { FiltersType } from './EventFilter';
import { EventImage } from './EventsShared';
import { CTAButton, formatDate, initGoogleAds } from './Utils';
import CustomAuthProvider from './admin/CustomAuthProvider';
import { GroupedEventsType } from '../pages/Main';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const EventsList = ({
  events,
  filters,
  thisWeekOnly = false,
}: {
  events: GroupedEventsType | null;
  filters: FiltersType;
  thisWeekOnly?: boolean;
}) => {
  const [filteredEvents, setFilteredEvents] =
    useState<GroupedEventsType | null>(events);
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const [previousUrl] = useState(window.location.href);
  const {
    weekendEvents = [],
    upcomingEvents = [],
    featuredEvents = [],
  } = filteredEvents || {};

  const handleOpenEventDetails = (event: EventType) => {
    setSelectedEvent(event);

    // update the URL
    if (thisWeekOnly) {
      window.location.href = `/event/${event.id}`;
    } else {
      window.history.pushState(null, 'Event', `/event/${event.id}`);
    }
  };

  const handleCloseEventDetails = () => {
    setSelectedEvent(null);

    // update the URL
    window.history.pushState(null, 'Homepage', previousUrl);
  };

  const filterEventsByField = useCallback(
    (events: EventType[]) => {
      return events.filter((event) => {
        return (
          (filters.artist === '' ||
            event.artists.some((artist) =>
              artist.name.toLowerCase().includes(filters.artist.toLowerCase()),
            )) &&
          (filters.city === '' ||
            event.venue.city
              .toLowerCase()
              .includes(filters.city.toLowerCase())) &&
          (filters.venue === '' ||
            event.venue.name
              .toLowerCase()
              .includes(filters.venue.toLowerCase())) &&
          (filters.tag === '' ||
            event.tags.some((tag) =>
              tag.name.toLowerCase().includes(filters.tag.toLowerCase()),
            )) &&
          (filters.date === '' ||
            new Date(event.datetime_start).toLocaleDateString('en-CA') ===
              filters.date)
        );
      });
    },
    [filters],
  );

  useEffect(() => {
    const tempEvents: GroupedEventsType | {} = { ...(events || {}) };
    Object.keys(tempEvents).forEach((key) => {
      tempEvents[key] = filterEventsByField(tempEvents[key]);
    });
    setFilteredEvents(tempEvents);
  }, [events, filters, filterEventsByField]);

  useEffect(() => {
    if (!events) {
      return;
    }
    initGoogleAds();
  }, [events]);

  if (events === null) {
    return (
      <Box>
        <LoadingPage
          loadingPrimary="Loading..."
          loadingSecondary="Please wait while we load the latest events!"
        />
      </Box>
    );
  }

  return (
    <div>
      {!thisWeekOnly && featuredEvents && featuredEvents?.length > 0 ? (
        <FeaturedEvents
          featuredEvents={featuredEvents}
          handleOpenEventDetails={handleOpenEventDetails}
        />
      ) : null}
      {weekendEvents && weekendEvents?.length > 0 ? (
        <>
          <Typography variant="h1" textAlign={'center'}>
            Events This Week
          </Typography>
          <Grid container spacing={2} justifyContent="center" mb={2}>
            {weekendEvents.map((event) => (
              <EventListItem
                key={event.id}
                event={event}
                handleOpenEventDetails={handleOpenEventDetails}
              />
            ))}
          </Grid>
        </>
      ) : null}
      <Box my={2}>
        <ins
          className="ADSENSE"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2902939122125433"
          data-ad-slot="9733784780"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Box>
      {upcomingEvents && !thisWeekOnly && upcomingEvents.length > 0 ? (
        <>
          <Typography variant="h1" textAlign={'center'}>
            Upcoming Events
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {upcomingEvents.map((event) => (
              <EventListItem
                key={event.id}
                event={event}
                handleOpenEventDetails={handleOpenEventDetails}
              />
            ))}
          </Grid>
        </>
      ) : null}
      {!featuredEvents?.length &&
        !weekendEvents?.length &&
        !upcomingEvents?.length && (
          <Typography variant="h6" textAlign={'center'} my={2}>
            No events to display.{' '}
            <Link color="ctaColor.main" href="/">
              Return to homepage
            </Link>
          </Typography>
        )}
      <SelectedEvent
        selectedEvent={selectedEvent}
        handleCloseEventDetails={handleCloseEventDetails}
      />
    </div>
  );
};

const EventListItem = (props: {
  event: EventType;
  handleOpenEventDetails: any;
  featured?: boolean;
  embed?: boolean;
  posterOnly?: boolean;
}) => {
  const {
    event,
    handleOpenEventDetails,
    featured = false,
    embed = false,
    posterOnly = false,
  } = props;
  const gridProps = {
    xs: 12,
    sm: 6,
    md: featured ? 6 : 4,
    lg: featured ? 6 : 3,
  };
  if (embed) {
    gridProps['sm'] = 12;
    gridProps['md'] = 12;
    gridProps['lg'] = 12;
    gridProps['height'] = '100%';
  }
  const onEventClick = () => {
    handleOpenEventDetails(event);
  };
  return (
    <Grid item {...gridProps}>
      <Paper
        sx={{
          mb: posterOnly ? 0 : 2,
          cursor: 'pointer',
          p: posterOnly ? 0 : 2,
          height: '100%',
          boxShadow: posterOnly ? 'none' : 'inhert',
        }}
        key={event.id}
      >
        <Box onClick={onEventClick}>
          <Box
            sx={{
              mx: 'auto',
              my: posterOnly ? 0 : 1,
              width: '100%',
              maxHeight: posterOnly ? '100%' : '200px',
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            <EventImage event={event} />
          </Box>
          {!posterOnly && (
            <Box>
              {CustomAuthProvider.isAdmin() && (
                <small style={{ float: 'left', marginRight: 5, marginTop: 5 }}>
                  <Link
                    variant="button"
                    href={`/manage/Events/${event.id}`}
                    color="secondary"
                    target="_blank"
                  >
                    <EditIcon sx={{ fontSize: '14px' }} />
                  </Link>{' '}
                </small>
              )}
              <Box
                component="a"
                href={`/event/${event.id}`}
                onClick={(e) => e.preventDefault()}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    lineHeight: '1.25em',
                    color: (theme) =>
                      theme.palette.ctaColor?.main ||
                      theme.palette.primary.main,
                  }}
                >
                  {event.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="left"
                alignContent="left"
                flexWrap="wrap"
                gap={0.5}
                fontSize="14px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  alignContent="center"
                  gap={0.5}
                  flex={1}
                >
                  <ScheduleIcon fontSize="small" />
                  <Box>{`${formatDate(event.datetime_start)} - ${formatDate(
                    event.datetime_end,
                    event.datetime_start,
                  )}`}</Box>
                </Box>
                {event.cost && (
                  <Box
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    gap={0.5}
                    flex={1}
                  >
                    <Box>
                      <strong>Cost:</strong> {event.cost}
                    </Box>
                  </Box>
                )}
                {event.venue && (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    flex={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <LocationOnIcon fontSize="small" />
                    {event.venue.name}, {event.venue.city}, {event.venue.state}
                  </Box>
                )}
                {event.artists?.length > 0 && (
                  <Box mb={1}>
                    {event.artists.map((artist: Artist) => (
                      <Chip
                        key={artist.id}
                        label={
                          <Box
                            className="limited-text-small"
                            sx={{
                              overflowWrap: 'break-word',
                              whiteSpace: 'normal',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '150px',
                            }}
                          >
                            {artist.name}
                          </Box>
                        }
                        size="small"
                        sx={{
                          m: 0.5,
                          backgroundColor: (theme) =>
                            theme.palette.ctaColor?.main,
                          color: '#fff',
                          height: 'auto',
                          '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                          },
                          p: '5px 0px',
                        }}
                      />
                    ))}
                  </Box>
                )}
                {event.tags?.length > 0 && (
                  <Box mb={1}>
                    {event.tags.map((tag: Tag) => (
                      <Chip
                        key={tag.id}
                        label={
                          <Box className="limited-text-small">{tag.name}</Box>
                        }
                        size="small"
                        sx={{ m: 0.5 }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

const FeaturedEvents = ({ featuredEvents, handleOpenEventDetails }) => {
  return (
    <>
      {featuredEvents && featuredEvents?.length > 0 && (
        <Box>
          <Typography variant="h1" textAlign={'center'}>
            Featured Events!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={10}
              slidesPerView={1}
              centeredSlides
              centeredSlidesBounds
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              navigation
              style={{
                width: '100%',
                maxWidth: '800px',
              }}
            >
              {featuredEvents.map((event) => (
                <SwiperSlide
                  key={event.id}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <EventListItem
                    event={event}
                    handleOpenEventDetails={handleOpenEventDetails}
                    featured
                    posterOnly
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Box textAlign="center" mb={2} mt={1}>
            <small>
              Want to see your event featured?
              <br />
              <CTAButton
                href="/contact?f=1#contact-us"
                text="Contact Us!"
              ></CTAButton>
            </small>
          </Box>
        </Box>
      )}
    </>
  );
};

const SelectedEvent = (props: {
  selectedEvent: EventType;
  handleCloseEventDetails: any;
}) => {
  const { selectedEvent, handleCloseEventDetails } = props;
  if (!selectedEvent) return null;
  return (
    <Dialog
      open={true}
      onClose={handleCloseEventDetails}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {handleCloseEventDetails ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseEventDetails}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="flex-start"
          gap={2}
        >
          {(selectedEvent.poster_image_url ||
            selectedEvent.artists.filter((artist: Artist) => artist.image_url)
              .length > 0 ||
            selectedEvent.venue['logo_image_url']) && (
            <Box flex={1}>
              <EventImage event={selectedEvent} />
            </Box>
          )}
          <EventCard event={selectedEvent} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const NoEvents = () => {
  return (
    <Paper sx={{ p: 6 }}>
      <Typography textAlign="center">
        No upcoming events to display, check back soon!
      </Typography>
    </Paper>
  );
};

export { EventListItem, EventsList };
