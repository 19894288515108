import { CreateParams, UpdateParams } from 'react-admin';
import {
  regularDataProvider,
  dataProvider,
  ADMIN_API_ROOT,
  API_ROOT,
} from '../components/admin/CustomDataProvider';

export default class ArtistsService {
  public static async updateArtistImage(artistId: string, file: File) {
    if (!file) {
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(
      `${ADMIN_API_ROOT}/artists/${artistId}/upload`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to update artist image');
    }
    return await response.json();
  }

  public static async getArtist(id: number) {
    return await regularDataProvider.getOne('Artists', { id });
  }

  public static async handleCreate(params: CreateParams<any>) {
    const { image_url, ...newData } = params.data;
    const newParams = {
      meta: params.meta,
      data: newData,
    };
    const result = await dataProvider.create('Artists', newParams);
    if (result?.data?.id && image_url?.rawFile) {
      const imageResult = await this.updateArtistImage(
        result.data.id,
        image_url.rawFile,
      );

      if (imageResult) {
        result.data = imageResult.updatedArtist;
      }
    }
    return result;
  }

  public static async handleUpdate(params: UpdateParams<any>) {
    const { image_url, ...newData } = params.data;
    const newParams = {
      id: params.id,
      previousData: params.previousData,
      meta: params.meta,
      data: newData,
    };
    if (!image_url) {
      newParams.data.image_url = '';
    }
    const result = await dataProvider.update('Artists', newParams);
    if (image_url?.rawFile) {
      const imageResult = await this.updateArtistImage(
        params.id,
        image_url.rawFile,
      );
      if (imageResult) {
        result.data = imageResult.updatedArtist;
      }
    }
    return result;
  }

  public static async getTopArtists() {
    const response = await fetch(`${API_ROOT}/artists/top`);
    if (!response.ok) {
      throw new Error('Failed to fetch top artists');
    }
    return await response.json();
  }
}
