import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const TwitchIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '1.6em' }} {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"
    />
  </SvgIcon>
);
export const MixCloudIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '2em' }} {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="m2.462 8.596l1.372 6.49h.319l1.372-6.49h2.462v6.808H6.742v-5.68l.232-.81h-.402l-1.43 6.49H2.854l-1.44-6.49h-.391l.222.81v5.68H0V8.596zM24 8.63v1.429L21.257 12L24 13.941v1.43l-3.235-2.329h-.348l-3.226 2.329v-1.43l2.734-1.94l-2.733-1.942V8.63l3.225 2.338h.348zm-7.869 2.75v1.24H9.304v-1.24z"
    />
  </SvgIcon>
);
export const SoundCloudIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '2em' }} {...props} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M11.56 8.87V17h8.76c1.85-.13 2.68-1.27 2.68-2.67c0-1.48-1.12-2.67-2.62-2.67c-.38 0-.7.08-1.03.22c-.24-2.34-2.23-4.17-4.68-4.17c-1.17 0-2.28.44-3.11 1.16m-.88 1.02c-.3-.18-.62-.32-.97-.39V17h1.39V9.34c-.15.16-.29.36-.42.55m-2.35-.54V17h.92V9.38c-.19-.03-.38-.04-.58-.04c-.12 0-.23 0-.34.01M6.5 10v7h.91V9.54c-.33.11-.64.27-.91.46m-1.67 2.5c-.06 0-.12-.06-.19-.09V17h.92v-6.14c-.37.48-.62 1.05-.73 1.64m-2.04-.28v4.69c.21.06.45.09.71.09h.22v-4.86c-.08-.01-.16-.02-.22-.02c-.26 0-.5.04-.71.1M1 14.56c0 .75.34 1.41.87 1.86v-3.71c-.53.44-.87 1.11-.87 1.85"
    />
  </SvgIcon>
);

export const SpotifyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '2em' }} {...props} viewBox="0 0 30 30">
    <path  
      fill="currentColor"
      d="M15,3C8.4,3,3,8.4,3,15s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z M19.731,21c-0.22,0-0.33-0.11-0.55-0.22 c-1.65-0.991-3.74-1.54-5.94-1.54c-1.21,0-2.53,0.22-3.63,0.44c-0.22,0-0.44,0.11-0.55,0.11c-0.44,0-0.77-0.33-0.77-0.77 s0.22-0.77,0.66-0.77c1.43-0.33,2.861-0.55,4.401-0.55c2.53,0,4.84,0.66,6.82,1.76c0.22,0.22,0.44,0.33,0.44,0.77 C20.39,20.78,20.06,21,19.731,21z M20.94,17.921c-0.22,0-0.44-0.11-0.66-0.22c-1.87-1.21-4.511-1.87-7.37-1.87 c-1.43,0-2.751,0.22-3.74,0.44c-0.22,0.11-0.33,0.11-0.55,0.11c-0.55,0-0.881-0.44-0.881-0.881c0-0.55,0.22-0.77,0.77-0.991 c1.32-0.33,2.641-0.66,4.511-0.66c3.08,0,5.94,0.77,8.361,2.2c0.33,0.22,0.55,0.55,0.55,0.881 C21.82,17.48,21.491,17.921,20.94,17.921z M22.37,14.4c-0.22,0-0.33-0.11-0.66-0.22c-2.2-1.21-5.39-1.98-8.47-1.98 c-1.54,0-3.19,0.22-4.621,0.55c-0.22,0-0.33,0.11-0.66,0.11c-0.66,0.111-1.1-0.44-1.1-1.099s0.33-0.991,0.77-1.1 C9.39,10.22,11.26,10,13.24,10c3.41,0,6.93,0.77,9.681,2.2c0.33,0.22,0.66,0.55,0.66,1.1C23.471,13.96,23.03,14.4,22.37,14.4z"/>
  </SvgIcon>
);

export const TikTokIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '2em' }} {...props} viewBox="0 0 30 30">
  <path 
    fill="currentColor"
    d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z"/>
  </SvgIcon>
);

export const VimeoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon sx={{ fontSize: '2em' }} {...props} viewBox="0 0 30 30">
    <path 
      fill="currentColor"
      d="M 5 5 L 5 27 L 27 27 L 27 5 L 5 5 z M 7 7 L 25 7 L 25 25 L 7 25 L 7 7 z M 20.878906 10 C 18.970906 10 17.676 10.827922 17 13.044922 C 17.844 12.682922 19.091313 12.588 18.945312 14 C 18.906312 14.478 18.48325 15.358484 17.90625 16.271484 C 16.30425 18.798484 15.91425 17.954 15.03125 12.375 C 14.78125 10.804 14.122781 10.072781 13.050781 10.175781 C 12.103781 10.261781 10.588 11.769063 9 13.164062 L 9.6464844 14 C 10.262484 13.565 10.622563 13.390625 10.726562 13.390625 C 11.622563 13.390625 12.082969 15.719359 13.167969 19.693359 C 13.722969 21.174359 14.398125 21.914062 15.203125 21.914062 C 16.499125 21.914062 18.084031 20.696672 19.957031 18.263672 C 21.769031 15.934672 22.811094 14.096813 22.871094 12.757812 C 22.922094 11.047813 22.192906 10 20.878906 10 z"/>
  </SvgIcon>
);
