import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

export type MenuElement = {
  name: string;
  to: string;
};
export const MenuElements: MenuElement[] = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'About',
    to: '/about',
  },
  {
    name: 'Contact',
    to: '/contact',
  },
  {
    name: "Artists",
    to: '/artists'
  },
  {
    name: "Venues",
    to: '/venues'
  }
];
const adminElement = {
  name: 'Admin',
  to: '/manage/',
};
const loginElement = {
  name: 'Login',
  to: '/manage/login',
};
export default function HeaderMenu() {
  const accessToken = localStorage.getItem('access_token');
  
  const getMenuElements = () => {
    const menuElementsCopy = [...MenuElements];
    if (accessToken) {
      menuElementsCopy.push(adminElement);
    }
  
    if (!accessToken) {
      menuElementsCopy.push(loginElement);
    }
    return menuElementsCopy;
  };

  return (
    <>
      <DesktopMenu menuElements={getMenuElements()} />
      <MobileMenu menuElements={getMenuElements()}  />
    </>
  );
}
