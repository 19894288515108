import { AppBar, Box, Toolbar } from '@mui/material';
import HeaderMenu from './components/HeaderMenu';
import logoImg from './images/logo.svg';
export const Header = () => {
  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mainBackground?.main || '#000',
      }}
    >
      <Toolbar>
        <Box sx={{ flex: 1, display: 'flex', py: 0.75 }}>
          <Box
            component="img"
            src={logoImg}
            alt="RogueValley.Events logo"
            sx={{ width: '225px', backgroundColor: '#fff' }}
          />
        </Box>
        <HeaderMenu />
      </Toolbar>
    </AppBar>
  );
};
