import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Box, Link } from '@mui/material';
import { Artist } from '../types';
import { Facebook, Twitter, Instagram, YouTube, Person } from '@mui/icons-material';
import { TikTokIcon, TwitchIcon, MixCloudIcon, SoundCloudIcon, SpotifyIcon, VimeoIcon } from '../components/SocialIcons';

interface ArtistCardProps {
  artist: Artist;
}

export const ArtistCard: React.FC<ArtistCardProps> = ({ artist }) => {
  const renderSocialLink = (platform: string, username: string | null) => {
    if (!username) return null;

    const urls: { [key: string]: string } = {
      facebook: `https://www.facebook.com/${username}`,
      twitter: `https://www.twitter.com/${username}`,
      instagram: `https://www.instagram.com/${username}`,
      youtube: `https://www.youtube.com/${username}`,
      tiktok: `https://www.tiktok.com/@${username}`,
      twitch: `https://www.twitch.tv/${username}`,
      mixcloud: `https://www.mixcloud.com/${username}`,
      soundcloud: `https://soundcloud.com/${username}`,
      spotify: `https://open.spotify.com/user/${username}`,
      vimeo: `https://vimeo.com/${username}`,
    };

    const icons: { [key: string]: JSX.Element } = {
      facebook: <Facebook />,
      twitter: <Twitter />,
      instagram: <Instagram />,
      youtube: <YouTube />,
      tiktok: <TikTokIcon />,
      twitch: <TwitchIcon />,
      mixcloud: <MixCloudIcon />,
      soundcloud: <SoundCloudIcon />,
      spotify: <SpotifyIcon />,
      vimeo: <VimeoIcon />,
    };

    return (
      <IconButton
        key={platform}
        size="small"
        href={urls[platform]}
        target="_blank"
        rel="noopener"
        aria-label={platform}
        sx={{ margin: '0 4px 4px 0' }} // Add margin to avoid crowding
      >
        {icons[platform]}
      </IconButton>
    );
  };

  return (
    <Link href={`/artist/${artist.id}`} underline="none" sx={{ textDecoration: 'none' }}>
      <Card sx={{ cursor: 'pointer' }}>
        {artist.image_url ? (
          <CardMedia
            component="img"
            height="140"
            image={artist.image_url}
            alt={artist.name}
          />
        ) : (
          <Box
            sx={{
              height: 140,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f0f0f0',
            }}
          >
            <Person sx={{ fontSize: 60, color: '#9e9e9e' }} />
          </Box>
        )}
        <CardContent>
          <Typography variant="h6" component="div">
            {artist.name}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap', // Ensure wrapping
            justifyContent: 'center',
            paddingBottom: 1,
            paddingX: 2, // Add padding to prevent links from touching edges
          }}
        >
          {renderSocialLink('facebook', artist.facebook_username)}
          {renderSocialLink('twitter', artist.twitter_username)}
          {renderSocialLink('instagram', artist.instagram_username)}
          {renderSocialLink('youtube', artist.youtube_username)}
          {renderSocialLink('tiktok', artist.tiktok_username)}
          {renderSocialLink('twitch', artist.twitch_username)}
          {renderSocialLink('mixcloud', artist.mixcloud_username)}
          {renderSocialLink('soundcloud', artist.soundcloud_username)}
          {renderSocialLink('spotify', artist.spotify_username)}
          {renderSocialLink('vimeo', artist.vimeo_username)}
        </Box>
      </Card>
    </Link>
  );
};

export default ArtistCard;
