import React, { useCallback, useRef } from 'react';
import { useNotify } from 'react-admin';
import { ImageInput } from 'react-admin';
import { ADMIN_API_ROOT } from './admin/CustomDataProvider';

export const AiAnalyzeImageInput = (props: any) => {
  const { dataCallback, ...otherProps } = props;
  const notify = useNotify();
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleFileChange = useCallback(
    async (file) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      notify(
        <div>
          Poster AI Analysis started, please wait...
          <button
            onClick={() => {
              abortController.abort();
              notify('AI analysis request was cancelled by the user.', {
                type: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
              });
            }}
          >
            Cancel
          </button>
        </div>,
        {
          type: 'info',
          autoHideDuration: null,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        },
      );

      if (file?.path) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${ADMIN_API_ROOT}/events/ai-analyze`, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `bearer ${localStorage.getItem('access_token')}`,
            },
            signal: abortController.signal,
          });

          const data = await response.json();
          if (dataCallback) {
            dataCallback(data);
          }

          notify('Poster AI analysis complete.', {
            type: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        } catch (error) {
          if (abortController.signal.aborted) {
            console.log('AI analysis request was cancelled.');
          } else {
            console.error('Error uploading image:', error);
            notify(
              `Error analyzing image. ${error instanceof Error ? error.message : 'Unknown error'}`,
              {
                type: 'warning',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
              },
            );
          }
        }
      }
    },
    [notify, dataCallback],
  );

  return (
    <ImageInput {...otherProps} accept="image/*" onChange={handleFileChange}>
      {otherProps.children}
    </ImageInput>
  );
};
