import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, Link, CardMedia } from '@mui/material';
import { Venue } from '../types';
import { Facebook, Twitter, Instagram, Business } from '@mui/icons-material';

interface VenueCardProps {
  venue: Venue;
}

export const VenueCard: React.FC<VenueCardProps> = ({ venue }) => {
  const renderSocialLink = (platform: string, username: string | null) => {
    if (!username) return null;

    const urls: { [key: string]: string } = {
      facebook: `https://www.facebook.com/${username}`,
      twitter: `https://www.twitter.com/${username}`,
      instagram: `https://www.instagram.com/${username}`,
    };

    const icons: { [key: string]: JSX.Element } = {
      facebook: <Facebook />,
      twitter: <Twitter />,
      instagram: <Instagram />,
    };

    return (
      <IconButton
        key={platform}
        size="small"
        href={urls[platform]}
        target="_blank"
        rel="noopener"
        aria-label={platform}
        sx={{ margin: '0 4px 4px 0' }}
      >
        {icons[platform]}
      </IconButton>
    );
  };

  return (
    <Link href={`/venue/${venue.id}`} underline="none" sx={{ textDecoration: 'none' }}>
      <Card sx={{ cursor: 'pointer' }}>
        {venue.logo_image_url ? (
            <CardMedia
                component="img"
                height="140"
                image={venue.logo_image_url}
                alt={venue.name}
            />
            ) : (
            <Box
                sx={{
                height: 140,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
                }}
            >
                <Business sx={{ fontSize: 60, color: '#9e9e9e' }} />
            </Box>
        )}
        <CardContent>
          <Typography variant="h6" component="div">
            {venue.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {venue.city}, {venue.state}
          </Typography>
          {venue.phone && (
            <Typography variant="body2" color="text.secondary">
              Phone: {venue.phone}
            </Typography>
          )}
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            paddingBottom: 1,
            paddingX: 2,
          }}
        >
          {renderSocialLink('facebook', venue.facebook_username)}
          {renderSocialLink('twitter', venue.twitter_username)}
          {renderSocialLink('instagram', venue.instagram_username)}
        </Box>
      </Card>
    </Link>
  );
};

export default VenueCard;
