import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  ReferenceInput,
  usePermissions,
  AutocompleteInput,
  ReferenceField,
  Filter,
} from 'react-admin';
import Icon from '@mui/icons-material/ManageAccounts';
export const ArtistManagersIcon = Icon;
interface ArtistManagerFilterProps {
  [key: string]: any;
}

export const ArtistManagerFilter = (props: ArtistManagerFilterProps) => (
  <Filter {...props}>
    <ReferenceInput
      label="Artist Name"
      source="artist_id" 
      reference="artists"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="Artist Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
    <ReferenceInput
      label="User Name"
      source="user_id" 
      reference="users"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="User Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);


export const ArtistManagersList = (props: any) => {
  const { permissions } = usePermissions();
  return (
    <List {...props} sort={{ field: 'user_id', order: 'ASC' }} filters={<ArtistManagerFilter />}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={permissions === 'Admin' ? undefined : false}
      >
        <ReferenceField source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="artist_id" reference="artists">
          <TextField source="name" />
        </ReferenceField>
        {permissions === 'Admin' && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const ArtistManagersEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput reference="users" disabled source="user_id" />
        <ReferenceInput reference="artists" disabled source="artist_id" />
      </SimpleForm>
    </Edit>
  );
};

export const ArtistManagersCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="user_id" reference="users">
          <AutocompleteInput
            optionText={(u) => `${u.name}, ${u.email}`}
            filterToQuery={(q) => ({ name: q })}
          />
        </ReferenceInput>
        <ReferenceInput source="artist_id" reference="artists">
          <AutocompleteInput
            optionText="name"
            filterToQuery={(q) => ({ name: q })}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
