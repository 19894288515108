import { useCallback, useRef, useState } from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import Constants from './Constants';
import { API_ROOT } from './components/admin/CustomDataProvider';
import { useLocation } from 'react-router-dom';

const GoogleRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '';

export const ContactForm = () => {
  return (
    <Box textAlign="center">
      <GoogleReCaptchaProvider reCaptchaKey={GoogleRecaptchaKey}>
        <ContactFormDetails />
      </GoogleReCaptchaProvider>
    </Box>
  );
};

const ContactFormDetails = () => {
  const [disabled, setDisabled] = useState(true);
  const [complete, setComplete] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [industry, setIndustry] = useState('');
  const [token, setToken] = useState('');
  const [posterFile, setPosterFile] = useState<File | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFeatured = searchParams.get('f') === '1';

  const refs = {
    name: useRef<HTMLInputElement>(),
    email: useRef<HTMLInputElement>(),
    phone: useRef<HTMLInputElement>(),
    message: useRef<HTMLInputElement>(),
    industry: useRef<HTMLSelectElement>(),
    poster: useRef<HTMLInputElement>(),
    featured: useRef<HTMLInputElement>(),
  };

  const handleSend = async () => {
    setDisabled(true);
    let fieldName: keyof typeof refs;
    const formData = new FormData();
    formData.append('token', token);

    for (fieldName in refs) {
      const field = refs[fieldName];
      if (!field.current) {
        continue;
      }
      if (fieldName === 'poster' && posterFile) {
        formData.append('poster', posterFile);
        continue;
      }
      if (fieldName !== 'industry' && !field.current.validity.valid) {
        if (field.current.validity.valueMissing) {
          alert('You must fill in all fields, please try again');
        } else {
          alert(`Please enter a valid value for ${fieldName}.`);
        }
        setDisabled(false);
        return;
      }
      if (fieldName === 'industry') {
        formData.append(fieldName, industry);
      } else {
        formData.append(fieldName, field.current.value);
      }
    }

    if (!token) {
      return;
    }

    await fetch(API_ROOT + '/contact', {
      method: 'POST',
      body: formData,
    });

    setComplete(true);
  };

  const setCaptcha = useCallback((t: string) => {
    setToken(t);
    setDisabled(false);
  }, []);

  if (complete) {
    return (
      <Box px={2} py={2} textAlign="center">
        <Typography
          mt={2}
          variant="h4"
          fontSize={Constants.responsiveFontSizes.h4}
          textTransform="none"
        >
          Your message has been sent, somebody will be in touch soon!
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h5" textTransform="none" textAlign={'right'} mt={1}>
        Contact Us
      </Typography>
      <Box
        sx={{
          p: 0,
          textAlign: 'center',
          width: '100%',
          mx: 'auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: '100%' }}
          mx="auto"
        >
          <TextField
            label="Name"
            type="text"
            variant="filled"
            color="secondary"
            id="contact-us"
            inputRef={refs.name}
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="filled"
            color="secondary"
            inputRef={refs.email}
            required
          />
          <FormControl variant="filled">
            <InputLabel id="user-type-label">Industry</InputLabel>
            <Select
              variant="filled"
              labelId="user-type-label"
              id="user-type"
              value={industry}
              onChange={(event) => setIndustry(event.target.value)}
              label="Industry"
              color="secondary"
              sx={{ textAlign: 'left' }}
              ref={refs.industry}
              required={true}
            >
              <MenuItem value={'Promoter'}>Promoter</MenuItem>
              <MenuItem value={'VenueOwner'}>Venue Owner</MenuItem>
              <MenuItem value={'Artist'}>Artist</MenuItem>
              <MenuItem value={'Manager'}>Manager</MenuItem>
              <MenuItem value={'Event Attendee'}>Event Attendee</MenuItem>
              <MenuItem value={'Fan'}>Fan</MenuItem>
            </Select>
          </FormControl>
          <MuiTelInput
            forceCallingCode
            disableDropdown
            label="Phone"
            variant="filled"
            color="secondary"
            inputRef={refs.phone}
            onChange={(newPhone) => {
              setPhoneValue(newPhone);
            }}
            inputProps={{ pattern: '[\\d]{3} [\\d]{3} [\\d]{4}' }}
            value={phoneValue}
            required
            defaultCountry="US"
          />
          <TextField
            label="Message"
            type="text"
            multiline={true}
            variant="filled"
            color="secondary"
            inputRef={refs.message}
            required
          />
          <InputLabel
            shrink
            htmlFor="poster-input"
            sx={{
              textAlign: 'left',
              mt: 1,
            }}
          >
            Attach Your Event Poster
          </InputLabel>
          <TextField
            id="poster-input"
            type="file"
            variant="filled"
            color="secondary"
            inputRef={refs.poster}
            onChange={(event) => {
              const target = event.target as HTMLInputElement; // Type assertion
              const file = target.files?.[0];
              if (file) {
                setPosterFile(file);
              }
            }}
            inputProps={{
              sx: {
                pt: 1,
                pb: 1,
              },
            }}
          />
          {isFeatured && (
            <TextField type="hidden" inputRef={refs.featured} value={'true'} />
          )}
          <GoogleReCaptcha onVerify={setCaptcha} />
          <Button
            disabled={disabled}
            sx={{ mt: 2 }}
            color="secondary"
            variant="contained"
            onClick={handleSend}
          >
            Send
          </Button>
        </Box>
      </Box>
    </>
  );
};
