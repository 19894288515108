// LoginForm.jsx
import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import authProvider from './components/admin/CustomAuthProvider';
import { MainContainer } from './MainContainer';
import { mdTheme } from './App';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  return (
    <ScopedCssBaseline>
      <ThemeProvider theme={mdTheme}>
        <MainContainer content={<LoginFormContiner />} />
      </ThemeProvider>
    </ScopedCssBaseline>
  );
};

const LoginFormContiner = () => {
  const [mode, setMode] = useState('login'); // 'login' or 'register'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [userType, setUserType] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login({ username: email, password });
    } catch (error) {
      notify(error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await authProvider.register({ email, password, name, phone, userType });
      notify('Registration successful. Logging you in now!');
    } catch (error) {
      notify(error);
      return;
    }

    try {
      await login({ username: email, password });
    } catch (error) {
      notify(error);
      return;
    }
  };

  return (
    <div>
      {mode === 'login' ? (
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
          {/* <Button onClick={() => setMode('register')}>Register</Button> */}
        </form>
      ) : (
        <form onSubmit={handleRegister}>
          <TextField
            label="Name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Phone"
            name="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              label="User Type"
              onChange={(e) => setUserType(e.target.value)}
            >
              <MenuItem value="Promoter">Event Organizer / Promoter</MenuItem>
              <MenuItem value="VenueOwner">Venue Owner</MenuItem>
              <MenuItem value="Artist">Artist</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit">Register</Button>
          <Button onClick={() => setMode('login')}>Back to Login</Button>
        </form>
      )}
      Don't have an account? <Link to="/contact">Contact Us!</Link>
      <Notification />
    </div>
  );
};

export default LoginForm;
