import { Admin, Resource } from 'react-admin';
import TagIcon from '@mui/icons-material/Tag';
import {
  ArtistList,
  ArtistEdit,
  ArtistCreate,
  ArtistIcon,
} from '../../views/admin/Artists';
import {
  EventList,
  EventEdit,
  EventCreate,
  EventIcon,
} from '../../views/admin/Events';
import { TagList, TagEdit, TagCreate } from '../../views/admin/Tags';
import {
  UsersList,
  UsersEdit,
  UsersCreate,
  UsersIcon,
} from '../../views/admin/Users';
import {
  VenueList,
  VenueEdit,
  VenueCreate,
  VenueIcon,
} from '../../views/admin/Venues';
import CustomAuthProvider from './CustomAuthProvider';
import { CustomDataProvider } from './CustomDataProvider';
import LoginForm from '../../LoginForm';
import { Layout } from '../../views/admin/Layout';
import {
  ArtistManagersList,
  ArtistManagersEdit,
  ArtistManagersCreate,
  ArtistManagersIcon,
} from '../../views/admin/ArtistManagers';
import {
  VenueUsersList,
  VenueUsersEdit,
  VenueUsersCreate,
  VenueUsersIcon,
} from '../../views/admin/VenueUsers';
const CustomAdminRoutes = () => {
  return (
    <Admin
      basename="/manage"
      dataProvider={CustomDataProvider}
      loginPage={LoginForm}
      authProvider={CustomAuthProvider}
      layout={Layout}
    >
      {(permissions) => (
        <>
          <Resource
            name="Events"
            list={EventList}
            edit={EventEdit}
            create={EventCreate}
            icon={EventIcon}
          />
          <Resource
            name="Venues"
            list={VenueList}
            edit={VenueEdit}
            create={VenueCreate}
            icon={VenueIcon}
          />
          <Resource
            name="Artists"
            list={ArtistList}
            edit={ArtistEdit}
            create={ArtistCreate}
            icon={ArtistIcon}
          />
          {permissions === 'Admin' && (
            <>
              <Resource
                name="users"
                list={UsersList}
                edit={UsersEdit}
                create={UsersCreate}
                icon={UsersIcon}
              />
              <Resource
                name="venue-users"
                options={{ label: 'Venue Managers' }}
                list={VenueUsersList}
                edit={VenueUsersEdit}
                create={VenueUsersCreate}
                icon={VenueUsersIcon}
              />
              <Resource
                name="artist-managers"
                options={{ label: 'Artist Managers' }}
                list={ArtistManagersList}
                edit={ArtistManagersEdit}
                create={ArtistManagersCreate}
                icon={ArtistManagersIcon}
              />
              <Resource
                name="Tags"
                list={TagList}
                edit={TagEdit}
                create={TagCreate}
                icon={TagIcon}
              />
            </>
          )}
        </>
      )}
    </Admin>
  );
};

export default CustomAdminRoutes;
